import React from "react";
import Navigation from "../Nav/Nav";
import Header from "../Header/Header";
import About from "../About/About";
import Contact from "Components/Contact/Contact";
import { useContactContext } from "Components/Context/ContactContext";
import Footer from "Components/Footer/Footer";
import Testimonials from "Components/Partners/Testimonials";
// import Footer from "Components/Footer/Footer";

const Screen = () => {
  const { sendEmailHandler } = useContactContext();
  return (
    <>
      <div
        style={{
          maxWidth: "1500px",
          margin: "0 auto",
          position: "relative",
          zIndex: "1",
          backgroundColor: "white",
        }}
      >
        <Navigation />
        <Header />
        <Testimonials />
        <div style={{ margin: "-51px 0 0  0" }}>
          <About />

          <Contact sendEmailHandler={sendEmailHandler} />

          <div style={{ textAlign: "center" }}>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Screen;
