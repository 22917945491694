import React from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import NavStyle from "./Nav.module.css";
import "style.css";

const Navigation = () => {
  const navBar = NavStyle.navBar;
  const containerNav = NavStyle.containerNav;
  return (
    <>
      <Navbar className={navBar} expand="lg">
        <Container className={containerNav}>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                href="https://www.amazon.com/Super-Credit-Kids-Vivian-Sykes/dp/B0CZL2KDN1/ref=sr_1_1?crid=1FRLUXRYXQVGT&dib=eyJ2IjoiMSJ9.jBMpDGhAThTI_nLTGDE48B6mpqVNnYIIhUN1X7BhspkpBWxqCGr4Vw2BM5avFkJVFvFfplFmkqHCZoFCJjD0zipd_dx2699EyUMQhUhDKTcfTsV_Bgd5g3pbGQvSr1uMbpT5bym7Mkm9ExO7BAmFslo9Ip-LJ1r0wSAID-wCG1AjsAXeW_evto9YUzVva0mp0t7G0ZaUC98oecZ1BaMs9Sdtt6iAstt7sFxB78Imn7I.8a4lCdO6DZZW1obCIdeUdJRhj08Fh1MJ2h9p7Mt3JZE&dib_tag=se&keywords=Super+Credit+For+Kid&qid=1716369499&s=books&sprefix=super+credit+for+kid%2Cstripbooks%2C181&sr=1-1"
                style={{ color: "#154D9B" }}
              >
                BUY BOOK
              </Nav.Link>
              <Nav.Link href="#testimonies" style={{ color: "#154D9B" }}>
                Testimonials
              </Nav.Link>
              <Nav.Link href="#contact" style={{ color: "#154D9B" }}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default Navigation;
