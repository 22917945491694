import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Screen from "./Components/Screen/Screen";
import ThankyouGeneral from "Components/Thank You/Thankyou";

import { ContactContextProvider } from "Components/Context/ContactContext";

import ThankyouTexas from "Components/Thank You/ThankyouTexas";

function App() {
  return (
    <BrowserRouter>
      <ContactContextProvider>
        {/* <Suspense fallback={<div>Loading...</div>}></Suspense> */}
        <Routes>
          <Route exact path="/" element={<Screen />} />

          <Route
            exact
            path="/thankyou/:firstName"
            element={<ThankyouGeneral />}
          />
        </Routes>
      </ContactContextProvider>
    </BrowserRouter>
  );
}

export default App;
