import React from "react";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import ContactStyle from "../Contact.module.css";

const Title = () => {
  const contactTitle = ContactStyle.contactTitle;
  const linkStyle = ContactStyle.linkStyle;
  const hrSelector = { margin: ".5rem 0" };
  const contactWrapperBottom = ContactStyle.contactWrapperBottom;
  const contactWrapperDesktop = ContactStyle.contactWrapperDesktop;

  return (
    <>
      <Row>
        <p className={contactTitle}>CONTACT US</p>
        <div className={contactWrapperDesktop}>
          {/* <div className={contactWrapper}></div> */}
          <div className={contactWrapperBottom}>
            <div>
              PH:{" "}
              <Link className={linkStyle} to="tel:4693733563">
                631.606.0141
              </Link>{" "}
              <br />
              EM:{" "}
              <a className={linkStyle} href="mailto:book@viviansykes.com">
                book@viviansykes.com
              </a>
            </div>
            <br />
            HOURS OF OPERATION <br /> Mon - Fri <br />
            9:00 AM – 5:00 PM
          </div>
        </div>
        <hr style={hrSelector} />
      </Row>
    </>
  );
};
export default Title;
