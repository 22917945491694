import React from "react";
import AboutStyles from "./About.module.css";
import { Image } from "react-bootstrap";
import Vivian from "../../assets/images/mobile-vivain-portrait.png";

const AboutDesktop = (props) => {
  const mainContainer = AboutStyles.mainContainer;
  const titleContainer = AboutStyles.titleContainer;
  const contentContainer = AboutStyles.contentContainer;
  const introContainer = AboutStyles.introContainer;
  const meetTeamTitle = AboutStyles.meetTeamTitle;
  const meetTeamBody = AboutStyles.meetTeamBody;
  const parentheses = AboutStyles.parentheses;
  const spanText = AboutStyles.spanText;
  const HeadShotsContainer = AboutStyles.HeadShotsContainer;

  const dash = <span className={parentheses}>-</span>;
  return (
    <>
      <main className={mainContainer}>
        <section className={contentContainer}>
          <div id="team" className={introContainer}>
            {" "}
            <div className={titleContainer}>
              <Image src={Vivian} />
              <div>
                <h3>Introducing, the author</h3>
                <h1>Vivian Sykes</h1>

                <p>
                  As a mother, educator, and writer, my objective is to educate
                  children on the significance and influence of credit. I strive
                  to provide every child with the knowledge needed to make wise
                  choices regarding credit management and usage.{" "}
                </p>
                <p>
                  "Super Credit For Kids" is a resource that I believe would
                  have greatly benefited me in my youth by deepening my
                  comprehension of credit. I am dedicated to guaranteeing that
                  our children are equipped with the necessary financial
                  education to excel and succeed in the realm of credit.
                </p>
                <p>
                  Now, with this valuable tool at their disposal, our children
                  can envision a brighter future through understanding credit in
                  its entirety.
                </p>
              </div>
            </div>
          </div>{" "}
        </section>{" "}
      </main>
    </>
  );
};

export default AboutDesktop;
