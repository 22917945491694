import React, { useContext } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import PartnerStyles from "./PartnerStyles.module.css";

// import partnerGroup1 from "../../assets/mobile/partners/1.png";
// import partnerGroup2 from "../../assets/mobile/partners/2.png";

import testimonial1 from "../../assets/images/mobile-portrait5.png";
import testimonial2 from "../../assets/images/mobile-portrait2.png";
import testimonal1Desk from "../../assets/images/desktop-portrait.png";
import testimonial3 from "../../assets/images/mobile-portrait3.png";
import testimonial4 from "../../assets/images/mobile-portrait4.png";

import {
  Accordion,
  AccordionContext,
  Button,
  useAccordionButton,
  Image,
} from "react-bootstrap";

import useMediaQuery from "services/useMediaQuery";

const Testimonials = () => {
  const matchedDesktop = useMediaQuery("(min-width: 728px)");
  const mainContainer = PartnerStyles.mainContainer;
  const titleContainer = PartnerStyles.titleContainer;
  const contentContainer = PartnerStyles.contentContainer;
  const introContainer = PartnerStyles.introContainer;
  const testimonialDiv = PartnerStyles.testimonialDiv;
  const img = PartnerStyles.img;
  const testClass = PartnerStyles.testClass;
  const contactBtn = PartnerStyles.contactBtn;
  const portTwo = PartnerStyles.portTwo;
  const portOne = PartnerStyles.portOne;
  const btnClass = PartnerStyles.btnClass;
  const testClass2 = PartnerStyles.testClass2;
  const readMoreButton = PartnerStyles.readMoreButton;
  const porthree = PartnerStyles.porthree;

  // Function to toggle the read more button
  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
      <Button
        type="button"
        style={
          {
            // display: isCurrentEventKey ? "none" : "inline",
          }
        }
        onClick={decoratedOnClick}
        className={readMoreButton}
      >
        {/* {children} */}
        {isCurrentEventKey ? "CLOSE" : "READ MORE"}
      </Button>
    );
  };
  //-----------------------------------------------------------------------------------

  const carouselContainer = (
    <Carousel
      autoPlay={true}
      showThumbs={false}
      showArrows={true}
      showIndicators={false}
      infiniteLoop={true}
    >
      <div className={img}>
        <Image src={testimonial4} />
        <h3 className={testClass2}>
          “We’re proud to take the journey of learning about generational wealth
          building & financial education with our little ones. I’m grateful for
          this book and excited to start laying the foundation early for my
          little one! Thank you Vivian for writing this book & investing in our
          youth”
          <p>-Kim Lara</p>
        </h3>
      </div>
      <div className={img}>
        <Image src={testimonial3} />
        <h3 className={testClass2}>
          “I love how this book is straight to the point. Super Credit for Kids
          teaches children very young about having better credit habits when
          they grow older, I believe the structure taught in this book are very
          unique. in my opinion this book is a great find, and the only one I
          know of that exists.”
          <p>-Damien</p>
        </h3>
      </div>
      <div className={img}>
        <Image src={testimonial1} className={porthree} />
        <h3 className={testClass2}>
          “I am so thankful for this book, Vivian does an excellent job at
          catering to littles, explaining credit management in simple and
          playful ways. My little ones enjoyed learning about financial freedom
          as much as I enjoyed reading it to them.”
          <p>-Cavel Evans</p>
        </h3>
      </div>
      <div className={img}>
        <Image src={testimonal1Desk} />
        <h3 className={testClass}>
          “Absolutely amazing This information is needed for my child at a young
          age ”<p>-Amazon Customer</p>
        </h3>
      </div>
      <div className={img}>
        <Image src={testimonial2} className={portTwo} />
        <h3 className={testClass2}>
          “I LOVE THIS BOOK FOR MY BABY GIRL! It is so important for me to
          provide right knowledge and resources early, I am excitied this book
          can truly set them up for success in the future.”
          <p>-Amazon Customer</p>
        </h3>
      </div>
    </Carousel>
  );

  const carouselContainer2 = (
    <Carousel
      autoPlay={true}
      showThumbs={false}
      showArrows={true}
      showIndicators={false}
      infiniteLoop={true}
    >
      <div className={img}>
        <Image src={testimonial4} />
        {/* kim */}
        <h3 className={testClass2}>
          “We’re proud to take the journey of learning about generational wealth
          building & financial education with our little ones. I’m grateful for
          this book and excited to start laying the foundation early for my
          little one! Thank you Vivian for writing this book & investing in our
          youth”
          <p>-Amazon Customer</p>
        </h3>
      </div>
      <div className={img}>
        {/* damien */}
        <Image src={testimonial3} />
        <h3 className={testClass2}>
          “I love how this book is straight to the point. Super Credit for Kids
          teaches children very young about having better credit habits when
          they grow older, I believe the structure taught in this book are very
          unique. in my opinion this book is a great find, and the only one I
          know of that exists.”
          <p>-Kim Lara</p>
        </h3>
      </div>
      {/* cavel */}
      <div className={img}>
        <Image src={testimonial1} className={porthree} />
        <h3 className={testClass2}>
          “I am so thankful for this book, Vivian does an excellent job at
          catering to littles, explaining credit management in simple and
          playful ways. My little ones enjoyed learning about financial freedom
          as much as I enjoyed reading it to them.”
          <p>-Damien</p>
        </h3>
      </div>
      <div className={img}>
        {/* amazon */}
        <Image src={testimonal1Desk} />
        <h3 className={testClass}>
          “Absolutely amazing. This information is <br /> needed for my child at
          a young age ”<p>-Cavel Evans</p>
        </h3>
      </div>
      <div className={img}>
        {/* amazon */}
        <Image src={testimonial2} className={portTwo} />
        <h3 className={testClass2}>
          “I LOVE THIS BOOK FOR MY BABY GIRL! It is so important for me to
          provide right knowledge and resources early, I am excited this book
          can truly set them up for success in the future.”
          <p>-Amazon Customer</p>
        </h3>
      </div>
    </Carousel>
  );

  const whyBuySuperCredit = (
    <>
      <Accordion defaultActiveKey="0" alwaysOpen={false}>
        <h3>
          As Parents we know about credit and the need to attain it. We know the
          importance of a good credit score and the consequences of a bad one.
        </h3>

        <Accordion.Collapse eventKey="1">
          <>
            <h3>
              However, do our children know? If you answered no, then this book
              will provide a solution to educate your youth in credit
              management, building a firm foundation on how to use credit with
              wisdom. With this book your children will gain the basic knowledge
              to avoid the common pitfalls we fell victim to in our youth.
              <hr style={{ margin: "1.5vw 0 0 0", border: "none" }} /> Unlike
              when we were young, our children can become properly educated in
              the popular areas of credit and interest, learning in a fun and
              interactive way how credit works and why there is a need for it.
              Even though we were uneducated about credit and compound interest,
              it is my hope that your children will be.
              <hr style={{ margin: "1.5vw 0 0 0", border: "none" }} />
              The goal of "Super Credit for Kids" is simple, for our youth to
              build wisdom and lead their generation in the proper understanding
              of the infamous world of credit management. Purchase your copy
              today and join the fight to educate our children about all things
              credit
            </h3>
          </>
        </Accordion.Collapse>
        <ContextAwareToggle eventKey="1"></ContextAwareToggle>
      </Accordion>
    </>
  );
  // ---------------------------------------------------------------------------
  const desktopWhySuperCredit = (
    <>
      <h3>
        As Parents we know about credit and the need to attain it. We know the
        importance of a good credit score and the consequences of a bad one.
        However, do our children know?
        <hr style={{ margin: "1.5vw 0 0 0", border: "none" }} />
        If you answered no, then this book will provide a solution to educate
        your youth in credit management, building a firm foundation on how to
        use credit with wisdom. With this book your children will gain the basic
        knowledge to avoid the common pitfalls we fell victim to in our youth.
        <hr style={{ margin: "1.5vw 0 0 0", border: "none" }} /> Unlike when we
        were young, our children can become properly educated in the popular
        areas of credit and interest, learning in a fun and interactive way how
        credit works and why there is a need for it. Even though we were
        uneducated about credit and compound interest, it is my hope that your
        children will be.
        <hr style={{ margin: "1.5vw 0 0 0", border: "none" }} />
        The goal of "Super Credit for Kids" is simple, for our youth to build
        wisdom and lead their generation in the proper understanding of the
        infamous world of credit management. Purchase your copy today and join
        the fight to educate our children about all things credit
      </h3>
    </>
  );

  return (
    <>
      <main id="testimonies" className={mainContainer}>
        <section className={contentContainer}>
          <div className={introContainer}>
            {!matchedDesktop && (
              <>
                <div className={titleContainer}>
                  <h3>
                    WHY BUY SUPER CREDIT <br /> FOR KIDS?
                  </h3>

                  <div className={testimonialDiv}>{whyBuySuperCredit}</div>

                  <h3></h3>
                  <div className={testimonialDiv}>
                    {/* <h3>
                      Super Credit For Kids is a trailblazer in its industry,
                      loved by parents and certain to engage your children too.
                    </h3> */}
                  </div>
                  {carouselContainer}
                </div>

                {/* <p>DO YOU HAVE QUESTIONS</p>
                <div className={contactBtn}>
                  <Button href="#contact" className={btnClass}>
                    CONTACT VIVIAN
                  </Button>
                </div> */}
              </>
            )}

            {matchedDesktop && (
              <>
                <div className={titleContainer}>
                  <h3>WHY BUY SUPER CREDIT FOR KIDS?</h3>

                  <div className={testimonialDiv}>{desktopWhySuperCredit}</div>
                  <h2></h2>
                  <div className={testimonialDiv}>
                    {/* <h3>
                      Super Credit For Kids is a trailblazer in its industry,
                      loved by parents and certain to engage your children too.
                    </h3> */}
                  </div>

                  {carouselContainer2}
                </div>

                <section></section>
                <p>DO YOU HAVE QUESTIONS</p>
                <div className={contactBtn}>
                  <Button href="#contact" className={btnClass}>
                    CONTACT VIVIAN
                  </Button>
                </div>
              </>
            )}
          </div>
        </section>
      </main>
    </>
  );
};

export default Testimonials;
