import React from "react";
import FooterStyle from "./FooterStyle.module.css";
import { Container } from "react-bootstrap";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const footerDesktop = FooterStyle.footerDesktop;
  const linkStyle = {
    color: "#aeaeae",
    textDecoration: "none",
    fontSize: "1rem",
  };

  return (
    <footer className={footerDesktop} style={{ padding: "0" }}>
      <Container>{/* <FooterTitle /> */}</Container>
      <div
        style={{
          backgroundColor: "rgb(239 242 206)",
          padding: ".3rem",
          color: "black",
        }}
      >
        COPYRIGHT @ {currentYear} supercreditforkids.com <br />
        Website designed and developed by{" "}
        <a style={linkStyle} href="https://www.designinglory.com">
          DESIGNINGLORY.COM
        </a>
      </div>
    </footer>
  );
};

export default Footer;
