import { Card, Col, Form, Row } from "react-bootstrap";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "./Form Fields/Title";
import Name from "./Form Fields/Name";
import Email from "./Form Fields/Email";
import SubmitButton from "./Form Fields/SubmitButton";
import ContactStyle from "./Contact.module.css";
import Message from "./Form Fields/Message";
import SubmitButtonDisabled from "./Form Fields/SubmitButtonDisabled";
import emailjs from "@emailjs/browser";
import Phone from "./Form Fields/Phone";

const Contact = ({ sendEmailHandler }) => {
  const [visibility, setVisibility] = useState(false);

  const formStyle = ContactStyle.formStyle;
  const contact = ContactStyle.contact;
  const checkForm = ContactStyle.checkForm;
  const nameDiv = ContactStyle.nameDiv;

  //for validate
  const [checked, setChecked] = useState(true);

  // for checkbox
  const [boxIsChecked, setBoxIsChecked] = useState(false);

  // for checkbox
  const onChangeChoice1 = (e) => {
    if (e.target.name === "content" && e.target.checked === true) {
      setChecked(false);
    }

    if (e.target.checked === false) {
      setChecked(true);
    }
  };
  // ---------------------------------------------------------------
  const form = useRef();
  const navigate = useNavigate();
  const SERVICEID = "service_n4zcjop";
  const TEMPLATEID = "supercreditforkidsform2";
  const PUBLICKEY = "JGR_romj3_gI4gwyv";

  emailjs.init(PUBLICKEY);
  console.log("PUBLICKEY", PUBLICKEY);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICEID, TEMPLATEID, form.current).then(
      (result) => {
        navigate(`/thankyou/${form.current.firstName.value}`);
        console.log("name", form.current.firstName.value);
        console.log("result", result);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Form
            id="contact"
            ref={form}
            onSubmit={sendEmail}
            className={contact}
            autoComplete="on"
          >
            <Card className={formStyle}>
              <Title />

              {/* Form Fields ---------------------------------*/}
              {!visibility && (
                <>
                  <div className={nameDiv}>
                    <Name />
                    <Phone />
                  </div>
                  <Email />
                  <Message />

                  <div
                    style={{
                      padding: ".5rem",
                    }}
                  >
                    <Form.Check
                      id="content"
                      name="content"
                      label="By checking the box you agree to us contacting you."
                      className={checkForm}
                      value={boxIsChecked}
                      onChange={onChangeChoice1}
                      required
                      onClick={() =>
                        setBoxIsChecked(
                          "I agree to Community of Love contacting me. "
                        )
                      }
                    />
                  </div>
                  {!checked ? <SubmitButton /> : <SubmitButtonDisabled />}
                </>
              )}
            </Card>
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default Contact;
