import React from "react";
import AboutStyles from "./About.module.css";
import useMediaQuery from "services/useMediaQuery";
import AboutDesktop from "./AboutDesktop";
import Vivian from "../../assets/images/mobile-vivain-portrait.png";
import { Image } from "react-bootstrap";

const About = (props) => {
  const matchedDesktop = useMediaQuery("(min-width: 728px)");

  const mainContainer = AboutStyles.mainContainer;
  const titleContainer = AboutStyles.titleContainer;
  const contentContainer = AboutStyles.contentContainer;
  const introContainer = AboutStyles.introContainer;
  const HeadShotsContainer = AboutStyles.HeadShotsContainer;

  const spanText = AboutStyles.spanText;

  return (
    <>
      {!matchedDesktop && (
        <main className={mainContainer}>
          <section className={contentContainer}>
            <div id="team" className={introContainer}>
              <div className={titleContainer}>
                <h3>Introducing, the author</h3>
                <h1>Vivian Sykes</h1>
                <Image src={Vivian} />
                <p>
                  As a mother, educator, and writer, my objective is to educate
                  children on the significance and influence of credit. I strive
                  to provide every child with the knowledge needed to make wise
                  choices regarding credit management and usage.{" "}
                </p>
                <p>
                  "Super Credit For Kids" is a resource that I believe would
                  have greatly benefited me in my youth by deepening my
                  comprehension of credit. I am dedicated to guaranteeing that
                  our children are equipped with the necessary financial
                  education to excel and succeed in the realm of credit.
                </p>
                <p>
                  Now, with this valuable tool at their disposal, our children
                  can envision a brighter future through understanding credit in
                  its entirety.
                </p>
              </div>
            </div>
          </section>
        </main>
      )}

      {matchedDesktop && <AboutDesktop />}
    </>
  );
};

export default About;
