import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import Facebook from "../../assets/images/facebook-icon.png";
import InstaGram from "../../assets/images/ig-icon.png";
import HeaderStyles from "./Header.module.css";
import useMediaQuery from "services/useMediaQuery";
import logoImg from "../../assets/images/logo.png";
import largeCloud from "../../assets/images/large-cloud.png";

const Header = () => {
  const matchedDesktop = useMediaQuery("(min-width: 728px)");

  const mainContainer = HeaderStyles.mainContainer;
  const logo = HeaderStyles.logo;
  const titleContainer = HeaderStyles.titleContainer;
  const contentContainer = HeaderStyles.contentContainer;
  const introContainer = HeaderStyles.introContainer;
  const spanText = HeaderStyles.spanText;
  const heading = HeaderStyles.heading;
  const subTitle = HeaderStyles.subTitle;

  const bigCloud = HeaderStyles.bigCloud;
  const smLinkContainer = HeaderStyles.smLinkContainer;
  const link = HeaderStyles.link;
  const igLink = HeaderStyles.igLink;
  const blueInfoWheel = HeaderStyles.blueInfoWheel;
  const vivianSykes = HeaderStyles.vivianSykes;
  const buyNowBtn = HeaderStyles.buyNowBtn;
  const btnContainer = HeaderStyles.btnContainer;
  const sampleBtn = HeaderStyles.sampleBtn;

  const url = "https://supercreditforkids.com/static/media/Sample.pdf";
  const facebookUrl = "https://www.facebook.com/profile.php?id=100073776066215";
  const igUrl = "https://www.instagram.com/viswonderful/?hl=en";

  return (
    <>
      {!matchedDesktop && (
        <main className={mainContainer}>
          <Row>
            <Col xs={4}>
              <Image src={logoImg} className={logo} />
            </Col>
            <Col xs={6}>
              <Image src={largeCloud} className={bigCloud} />

              <div className={smLinkContainer}>
                <a href={facebookUrl}>
                  <Image src={Facebook} className={link} />
                </a>
                <a href={igUrl}>
                  <Image src={InstaGram} className={igLink} />
                </a>
              </div>
            </Col>
          </Row>

          <section className={contentContainer}>
            <div className={introContainer}>
              {/* Super Credit Title. ------------------------------  */}
              <div className={titleContainer}>
                <h1>
                  <span className={spanText}> the Solution</span>
                  <span className={heading}>
                    {" "}
                    to help improve your children's financial future is at their
                    fingertips
                  </span>
                </h1>
              </div>
              {/* -------------------------------------------------------------- */}

              {/* Introducing sub title   -------------------------------------- */}
              <h1 className={subTitle}>Welcome to the </h1>
              <div>
                <p>Super Credit For Kids Children Book</p>{" "}
                <span className={vivianSykes}>By Vivian Sykes</span>
              </div>

              {/* -------------------------------------------------------------- */}

              {/* Blue Wheel  -------------------------------------------------- */}
              <div className={blueInfoWheel}>
                <div className={btnContainer}>
                  <Button
                    className={buyNowBtn}
                    href="https://www.amazon.com/Super-Credit-Kids-Vivian-Sykes/dp/B0CZL2KDN1/ref=sr_1_1?crid=1FRLUXRYXQVGT&dib=eyJ2IjoiMSJ9.jBMpDGhAThTI_nLTGDE48B6mpqVNnYIIhUN1X7BhspkpBWxqCGr4Vw2BM5avFkJVFvFfplFmkqHCZoFCJjD0zipd_dx2699EyUMQhUhDKTcfTsV_Bgd5g3pbGQvSr1uMbpT5bym7Mkm9ExO7BAmFslo9Ip-LJ1r0wSAID-wCG1AjsAXeW_evto9YUzVva0mp0t7G0ZaUC98oecZ1BaMs9Sdtt6iAstt7sFxB78Imn7I.8a4lCdO6DZZW1obCIdeUdJRhj08Fh1MJ2h9p7Mt3JZE&dib_tag=se&keywords=Super+Credit+For+Kid&qid=1716369499&s=books&sprefix=super+credit+for+kid%2Cstripbooks%2C181&sr=1-1"
                  >
                    BUY NOW
                  </Button>
                  <Button className={sampleBtn} href={url}>
                    READ SAMPLE
                  </Button>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
      {matchedDesktop && (
        <>
          <main className={mainContainer}>
            <Row>
              <Col xs={4}>
                <Image src={logoImg} className={logo} />
              </Col>
              <Col xs={6}>
                <Image src={largeCloud} className={bigCloud} />

                <div className={smLinkContainer}>
                  <a href={facebookUrl}>
                    <Image src={Facebook} className={link} />
                  </a>
                  <a href={igUrl}>
                    <Image src={InstaGram} className={igLink} />
                  </a>
                </div>
              </Col>
            </Row>

            <div className={introContainer}>
              {/* Super Credit Title. ------------------------------  */}
              <div className={titleContainer}>
                <h1>
                  the Solution
                  <span className={heading}>
                    {" "}
                    to help improve your children's financial future is at their
                    fingertips
                  </span>
                </h1>
              </div>
              {/* -------------------------------------------------------------- */}

              {/* Introducing sub title   -------------------------------------- */}
              <h1>Welcome to the </h1>
              <div>
                <p>Super Credit For Kids Children Book</p>{" "}
                <span className={vivianSykes}>By Vivian Sykes</span>
              </div>
              {/* Blue Wheel  -------------------------------------------------- */}
              <div>
                <div className={btnContainer}>
                  <Button
                    className={buyNowBtn}
                    href="https://www.amazon.com/dp/B0CZL2KDN1?ref_=cm_sw_r_cp_ud_dp_NA1A3TZVJQHRZNHQNK7Y#customerReviews"
                  >
                    BUY NOW
                  </Button>
                  <Button className={sampleBtn} href={url}>
                    READ SAMPLE
                  </Button>
                </div>
              </div>
              {/* -------------------------------------------------------------- */}
            </div>
          </main>
        </>
      )}
    </>
  );
};

export default Header;
